<template>
  <div class="mecarts">
    <div class="tops">
      <div class="chakan" style="color: #00a3e0; font-size: 16px">查看商品</div>
      <div class="toptight">
        <!-- <el-input
          placeholder="请输入内容"
          v-model="input3"
          class="input-with-select"
        >
          <el-button slot="append" type="primary">搜素</el-button>
        </el-input> -->
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableDataObg.skuList"
      :header-cell-style="{ background: '#F3F3F3 ', color: '#535353' }"
      tooltip-effect="dark"
      style="width: 100%"
      @select-all="handleSelectionChange"
      @select="handleselect"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="商品">
        <template slot-scope="scope">
          <div class="goodscenter">
            <img class="imgs" :src="scope.row.goodsSku.thumbnail" alt="" />
            <div class="goodsright">
              <div class="titles">{{ scope.row.goodsSku.goodsName }}</div>
              <div class="skutext">
                {{ scope.row.goodsSku.quantity
                }}{{ scope.row.goodsSku.goodsUnit }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="utilPrice" label="单价(元)" width="120">
      </el-table-column>
      <el-table-column width="200" label="数量">
        <template slot-scope="scope">
          <el-input-number
            v-model="scope.row.num"
            @change="handleChange($event, scope.row.goodsSku.id)"
            :min="1"
            label="数量"
          ></el-input-number
        ></template>
      </el-table-column>
      <el-table-column prop="subTotal" label="小计" width="120">
        <template slot-scope="scope">
          <span>{{ scope.row.subTotal }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(1, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="flextops">
      <div class="bottleft">
        <el-checkbox v-model="checked" @change="changes">全选</el-checkbox>
        <span class="texts" @click.stop="handleDelete(2)"
          >删除选中商品
          <span class="lsitsib" @click="allcarts">|</span> 清空购物车
        </span>
      </div>
      <div class="bottright">
        已选 <span class="one">{{ multipleSelection.length }}</span> 件商品
        <span class="textto">总价(不含运费):</span>
        <span class="price">￥{{ tableDataObg.priceDetailDTO.flowPrice }}</span>
        <div class="btns" @click="tomonysqian">立即结算</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getUsercarts,
  getCartsRemove,
  getCartsAllRemove,
  postcartschecked,
  postcartsnum,
  postcartstore,
} from "../../request/homeApi";
export default {
  data() {
    return {
      tableDataObg: {},
      checked: false,
      multipleSelection: [],
    };
  },

  mounted() {
    this.coartList();
  },
  methods: {
    allcarts() {
      getCartsAllRemove()
        .then((res) => {
          if (res.code == 200) {
            this.checked = false;
            this.$message.success("操作成功！");
            this.coartList();
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    // 删除一个
    handleDelete(index, row) {
      let data = [];
      if (index == 1) {
        data.push(row.goodsSku.id);
      }
      if (index == 2) {
        this.multipleSelection.forEach((item) => {
          data.push(item.goodsSku.id);
        });
      }
      data.join(",");
      getCartsRemove(data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.checked = false;
            this.$message.success("操作成功！");
            this.coartList();
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    // 立即结算
    tomonysqian() {
      // this.toggleSelection(this.tableDataObg.checkedSkuList);
      this.$router.push({ path: "/closeORaccountpage?way=CART" });
    },
    // 获取购物车列表
    coartList() {
      getUsercarts()
        .then((res) => {
          if (res.code == 200) {
            res.result.skuList.forEach((item, index) => {
              if (item.invalid) {
                res.result.skuList.splice(index, 1);
              }
            });
            this.tableDataObg = res.result;
            this.multipleSelection = [];
            this.multipleSelection = this.tableDataObg.checkedSkuList;
            this.$nextTick(() => {
              this.tableDataObg.checkedSkuList.forEach((row) => {
                this.tableDataObg.skuList.forEach((ims) => {
                  if (row.goodsSku.id == ims.goodsSku.id) {
                    this.$refs.multipleTable.toggleRowSelection(ims, true);
                  }
                });
              });
            });
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    handleChange(value, id) {
      let data = {
        skuId: id,
        num: value,
        cartType: "CART",
      };
      postcartsnum(data)
        .then((res) => {
          if (res.code == 200) {
            this.coartList();
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
      this.coartList();
    },
    changes(e) {
      console.log(e);
      if (e) {
        let data = {
          storeId: "1376369067769724928",
          checked: 1,
        };
        postcartstore(data)
          .then((res) => {
            if (res.code == 200) {
              this.coartList();
              this.multipleSelection = val;
            }
          })
          .catch((res) => {
            console.log("请求失败");
          });
      } else {
        let data = {
          storeId: "1376369067769724928",
          checked: 0,
        };
        postcartstore(data)
          .then((res) => {
            if (res.code == 200) {
              this.coartList();
              this.multipleSelection = val;
            }
          })
          .catch((res) => {
            console.log("请求失败");
          });
      }
    },
    // 单选一个购物车
    handleselect(selection, row) {
      console.log(row);
      var ischke = true;
      var result = this.multipleSelection.some(function (item) {
        if (item.goodsSku.id == row.goodsSku.id) {
          return true;
        }
      });
      if (!result) {
        this.multipleSelection.push(row);
        ischke = true;
      } else {
        this.multipleSelection.forEach((item, index) => {
          if (item.goodsSku.id == row.goodsSku.id) {
            ischke = false;
            this.multipleSelection.splice(index, 1);
          }
        });
      }
      let data = {
        skuId: row.goodsSku.id,
        checked: ischke,
      };
      postcartschecked(data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.coartList();
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    handleSelectionChange(val) {
      let data = {
        storeId: "1376369067769724928",
        checked: val.length > 0 ? 1 : 0,
      };
      postcartstore(data)
        .then((res) => {
          if (res.code == 200) {
            this.coartList();
            this.multipleSelection = val;
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mecarts {
  width: 100%;
  //   height: 100%;
  background: #ffffff;
  position: relative;
  padding: 20px 25px;
  margin-bottom: 20px;
  padding-bottom: 100px;
  .tops {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    columns: #00a3e0;
    .toptight {
      width: 300px;
    }
  }
  .flextops {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 53px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    .bottleft {
      display: flex;
      align-items: center;
      .texts {
        margin-left: 30px;
        font-weight: 400;
        color: #00a3e0;
        cursor: pointer;
        .lsitsib {
          font-size: 14px;
        }
      }
    }
    .bottright {
      display: flex;
      align-items: center;
      .one {
        color: #00a3e0;
      }
      .textto {
        padding-left: 20px;
      }
      .price {
        color: #f60000;
      }
      .btns {
        width: 120px;
        height: 52px;
        margin-left: 30px;
        text-align: center;
        line-height: 52px;
        background: #00a3e0;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
}
.goodscenter {
  display: flex;
  align-items: center;
  .imgs {
    width: 145px;
    height: 80px;
  }
  .goodsright {
    width: 270px;
    margin-left: 26px;
    .titles {
      font-size: 14px;
      color: #535353;
    }
    .skutext {
      margin-top: 10px;
      font-size: 14px;
      color: #818181;
    }
  }
}
</style>